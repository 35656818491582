import React from 'react';
import '../../css/home.css';
import '../../css/plasticInfo.css';
import fourpage2 from '../../photo/plasticInfo/fourpage2.png';
import fourpage1 from '../../photo/plasticInfo/fourpage1.png';

const PlasticInfo = () => {
  return (
      <div className="header">
        <div className="plasticinfo-background"></div>
        <div className="plasticinfo-content">
          <div className="plasticInfo-box-container">
            <div className="plasticInfo-box large-box">
              <p>우리동네<br/>PP 방앗간에서 버려지는<br/>작은 플라스틱을 빻고,<br/>녹이고, 굳히는<br/>작업을 통해<br/>실용적인 물건으로<br/>재탄생시킵니다!</p>
            </div>
            <div className="plasticInfo-box large-box">
              <span className='smalltitle'>왜요?</span><br/><p className='largeinfo'>대부분의 플라스틱 재활용이<br/> 가능합니다. 하지만! 손바닥보다<br/> 작은 크기의 플라스틱 재활용지원<br/> 선별과정에서 모두 탈락되어<br/> 소각되거나 매립되죠. 이렇게<br/> 썩지도 않는 플라스틱들을 땅에<br/> 버리는 대신, 필요한 물건으로<br/> 재탄생시키는 곳이 바로<br/><span className="plasticInfo-highlight2">플라스틱방앗간!</span></p>
            </div>
            <div className="plasticInfo-box small-box">
            <div className="title-img-container">
            <span className='smalltitle'>수거<br/> 가능한 &nbsp;&nbsp;<br/> 병뚜껑</span>
            <img src={fourpage1} alt="수거 가능한 병뚜껑" /><br/>
            </div>
              <span className='smallinfo'>음료병뚜껑,<br/> 병목고리<br/><span className="plasticInfo-highlight">재질 표시 없어도 됨</span></span><br/><br/>
              <div className="title-img-container">
              <span className='smalltitle'>수거<br/> 불가능한<br/> 병뚜껑</span>
              <img src={fourpage2} alt="수거 불가능한 병뚜껑" /><br/>
              </div>
              <span className='smallinfo'>병뚜껑 안쪽에 다른 재질이 부착된 경우<br/><span className="plasticInfo-highlight">재활용 불가</span></span>
            </div>
          </div>
        </div>
      </div>
  );
};

export default PlasticInfo;