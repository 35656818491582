import React from 'react';
import '../../css/home.css';
import socialg from '../../photo/pmintro/socialg.png';
import ppm from '../../photo/pmintro/ppm.png';
import ppmlogo from '../../photo/pmintro/ppmlogo.png';
import yellow from '../../photo/pmintro/yellow.png'

const PmIntro = () => {
  return (
    <div className="pmintro-header">
      <div className="pmintro-background-image"></div>
      <div className="content">
        <img src={ppmlogo} alt="ppmlogo" className="ppmlogo" />
        <img src={socialg} alt="socialg" className="socialg" />
        <img src={ppm} alt="ppm" className="ppm" />
        <img src={yellow} alt="yellow" className="yellow" />
      </div>
    </div>
  );
};

export default PmIntro;
