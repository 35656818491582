import React from 'react';
import NavigationBar from './NavigationBar';
import '../css/academy.css';
import '../css/home.css';

function Academy() {
  return (
      <div className="header">
      <NavigationBar />
      <div className="academy-background"></div>
    </div>
  );
}

export default Academy;
