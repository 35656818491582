import React from 'react';
import NavigationBar from './NavigationBar';
import '../css/notices.css';
import '../css/home.css';

function Notices() {
  return (
    <div className="header">
    <NavigationBar />
    <div className="notices-background"></div>
  </div>
);}

export default Notices;