import React from 'react';
import '../../css/home.css';
import '../../css/plasticIntro.css';

const PlasticIntro = () => {
  return (
      <div className="header">
        <div className="plasticintro-background"></div>
        <div className="content">
          <div className="plasticIntro-box-container">
            <div className="plasticIntro-box">
              <p>지역에서 모이고<br/>지역에서 만들어<br/>지역에서 소비되는<br/>재활용 자원 순환구조<br/> 만들기!</p>
            </div>
            <div className="plasticIntro-box">
              <p>청주에도 드디어<br/>플라스틱방앗간이<br/>생겼어요~!<br/>뭘 하는<br/> 방앗간이냐구요?</p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default PlasticIntro;