import React from 'react';
import '../../css/home.css';
import '../../css/plasticProcess.css';
import step1 from '../../photo/plasticProcess/step1.png';
import step2 from '../../photo/plasticProcess/step2.png';
import step3 from '../../photo/plasticProcess/step3.png';
import step4 from '../../photo/plasticProcess/step4.png';

const PlasticProcess = () => {
  return (
    <div className="header">
      <div className="plasticprocess-background"></div>
      <div className="content">
        <div className="plasticProcess-title">
          <span className='plasticProcess-title1'>병뚜껑이 재활용 되는 과정!</span><br/>
          <span className='plasticProcess-title2'>&lt;플라스틱 구슬 찍는 법&gt;</span>
        </div>
        <div className="plasticProcess-box-container">
          <div className="plasticProcess-box">
            <img src={step1} alt="뚜껑을 모아서 작은 조각으로 갈아요" />
            <p className="description">1 뚜껑을 모아서 작은<br/>조각으로 갈아요.</p>
          </div>
          <div className="plasticProcess-box">
            <img src={step2} alt="갈린 플라스틱 조각은 사출기에 넣어서 녹입니다." />
            <p className="description">2 갈린 플라스틱 조각은<br/> 사출기에 넣어서<br/>(270°) 녹입니다.</p>
          </div>
          <div className="plasticProcess-box">
            <img src={step3} alt="녹은 플라스틱은 구슬모양 틀에 넣어 단단하게 굳힙니다." />
            <p className="description">3 녹은 플라스틱은<br/> 구슬모양 틀에 넣어<br/> 단단하게 굳힙니다!</p>
          </div>
          <div className="plasticProcess-box">
            <img src={step4} alt="병뚜껑으로 만든 업사이클링 구슬 탄생!" />
            <p className="description">4 병뚜껑으로 만든<br/> 업사이클링 구슬 탄생!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlasticProcess;