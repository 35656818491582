import React, { useState } from 'react';
import '../css/home.css';

const NavigationBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="home-nav">
      <div className="hamburger" onClick={toggleMenu}>
        &#9776;
      </div>
      <div className={`menu ${isOpen ? 'open' : 'close'}`}>
        <a href="/home" className="circle-button">소개</a>
        <a href="/notices" className="circle-button">공지사항</a>
        <a href="/location" className="circle-button">위치</a>
        <a href="/academy" className="circle-button">아카데미</a>
        <a href="/membership" className="circle-button">멤버쉽</a>
      </div>
    </nav>
  );
};

export default NavigationBar;
