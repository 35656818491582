import React, { useState } from 'react';
import '../../css/home.css';
import '../../css/manito.css';
import potImage1 from '../../photo/manito/upcycling.png';
import potImage2 from '../../photo/manito/coconut.png';
import potImage3 from '../../photo/manito/claypot.png';
import arrowImage from '../../photo/manito/arrow.png';  

import plantImage1 from '../../photo/manito/bambooshoot.png';
import plantImage2 from '../../photo/manito/gasiogalpi.png';
import plantImage3 from '../../photo/manito/aspleniumantiquum.png';

import manitoImage1 from '../../photo/manito/cozy.png';
import manitoImage2 from '../../photo/manito/soft.png';
import manitoImage3 from '../../photo/manito/fluffy.png';

import accessoryImage1 from '../../photo/manito/alok.png';
import accessoryImage2 from '../../photo/manito/mystery.png';
import accessoryImage3 from '../../photo/manito/lively.png';

// 대체 이미지 임포트
import alternateImage1 from '../../photo/manito/alokselect2.png';
import alternateImage2 from '../../photo/manito/mysterselect.png';
import alternateImage3 from '../../photo/manito/livelyselect.png';

function Manito() {
  const [selectedPot, setSelectedPot] = useState(null);
  const [selectedPlant, setSelectedPlant] = useState(null);
  const [selectedManito, setSelectedManito] = useState(null);
  const [selectedAccessory, setSelectedAccessory] = useState(null);
  const [alternativeImage, setAlternativeImage] = useState(null); 

  const pots = [
    { id: 1, name: '업사이클링화분', image: potImage1 },
    { id: 2, name: '코코넛화분', image: potImage2 },
    { id: 3, name: '토분', image: potImage3 }
  ];

  const plants = [
    { id: 1, name: '죽절초', image: plantImage1 },
    { id: 2, name: '가시오갈피', image: plantImage2 },
    { id: 3, name: '파초일엽', image: plantImage3 }
  ];

  const manitos = [
    { id: 1, name: '포근이', image: manitoImage1 },
    { id: 2, name: '보들이', image: manitoImage2 },
    { id: 3, name: '폭신이', image: manitoImage3 }
  ];

  const accessories = [
    { id: 1, name: '알록', image: accessoryImage1 },
    { id: 2, name: '신비', image: accessoryImage2 },
    { id: 3, name: '발랄', image: accessoryImage3 }
  ];

  const accessoryAlternateImages = {
    1: alternateImage1,
    2: alternateImage2,
    3: alternateImage3
  };

  const toggleSelection = (item, selectedSetter, selectedState, setAlternateImage = null) => {
    if (selectedState && selectedState.id === item.id) {
      selectedSetter(null);
      if (setAlternateImage) setAlternateImage(null); 
    } else {
      selectedSetter(item);
      if (setAlternateImage) setAlternateImage(accessoryAlternateImages[item.id]); 
    }
  };

  const getPlantClassName = (plantId) => {
    if (plantId === 1) { 
      return 'behind-pot';
    } else if (plantId === 2) {
      return 'behind-pot2';
    } else if (plantId === 3) { 
      return 'in-front-pot';
    }
    return '';
  };

  return (
    <div className="manito-header">
      <div className="manito-background"></div>
      <div className="content main-content">
        <div className="content-body">
          <div className="selection-steps">
            <div className="step">
              <div className="step-title">
                <div className="step-circle">STEP 1</div>
                <div className="step-text">화분</div>
              </div>
              <div className="options">
                {pots.map(pot => (
                  <button
                    key={pot.id}
                    onClick={() => toggleSelection(pot, setSelectedPot, selectedPot)}
                    className={selectedPot && selectedPot.id === pot.id ? 'selected' : ''}
                  >
                    <img src={pot.image} alt={pot.name} className="option-image" />
                    <div className="option-text">{pot.name}</div>
                  </button>
                ))}
              </div>
            </div>
            <div className="step">
              <div className="step-title">
                <div className="step-circle">STEP 2</div>
                <div className="step-text">식물</div>
              </div>
              <div className="options">
                {plants.map(plant => (
                  <button
                    key={plant.id}
                    onClick={() => toggleSelection(plant, setSelectedPlant, selectedPlant)}
                    className={selectedPlant && selectedPlant.id === plant.id ? 'selected' : ''}
                  >
                    <img src={plant.image} alt={plant.name} className="option-image" />
                    <div className="option-text">{plant.name}</div>
                  </button>
                ))}
              </div>
            </div>
            <div className="step">
              <div className="step-title">
                <div className="step-circle">STEP 3</div>
                <div className="step-text">마니또</div>
              </div>
              <div className="options">
                {manitos.map(manito => (
                  <button
                    key={manito.id}
                    onClick={() => toggleSelection(manito, setSelectedManito, selectedManito)}
                    className={selectedManito && selectedManito.id === manito.id ? 'selected' : ''}
                  >
                    <img src={manito.image} alt={manito.name} className="option-image" />
                    <div className="option-text">{manito.name}</div>
                  </button>
                ))}
              </div>
            </div>
            <div className="step">
              <div className="step-title">
                <div className="step-circle">STEP 4</div>
                <div className="step-text">악세사리</div>
              </div>
              <div className="options">
                {accessories.map(accessory => (
                  <button
                    key={accessory.id}
                    onClick={() => toggleSelection(accessory, setSelectedAccessory, selectedAccessory, setAlternativeImage)}
                    className={selectedAccessory && selectedAccessory.id === accessory.id ? 'selected' : ''}
                  >
                    <img src={accessory.image} alt={accessory.name} className="option-image" />
                    <div className="option-text">{accessory.name}</div>
                  </button>
                ))}
              </div>
            </div>
          </div>
          <img src={arrowImage} alt="Next Step" className="arrow-image" />
          <div className="result-display">
            <div className="result-box">
              {selectedPot && <img src={selectedPot.image} alt={selectedPot.name} className="selected-image selected-pot" />}
              {selectedPlant && <img src={selectedPlant.image} alt={selectedPlant.name} className={`selected-image selected-plant ${getPlantClassName(selectedPlant.id)}`} />}
              {selectedManito && <img src={selectedManito.image} alt={selectedManito.name} className="selected-image selected-manito" />}
              {alternativeImage && <img src={alternativeImage} alt={selectedAccessory.name} className="selected-image selected-accessory" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manito;
