import React from 'react';
import '../css/home.css';
import '../css/manito.css'
import '../css/plasticInfo.css'
import '../css/plasticProcess.css'

import PmInfo from './Intro/PmIntro';
import PlasticInfo from './Intro/PlasticInfo';
import PlasticIntro from './Intro/PlasticIntro';
import PlasticProcess from './Intro/PlasticProcess';
import Manito from './Intro/Manito';
import NavigationBar from './NavigationBar';

function Home() {
  return (
    <div className="home">
      <NavigationBar />
      <PmInfo />
      <PlasticIntro/>
      <PlasticInfo />
      <PlasticProcess/>
      <Manito/>
    </div>
  );
}

export default Home;
