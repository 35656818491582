import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Notices from './components/Notices';
import Location from './components/Location';
import Academy from './components/Academy';
import Membership from './components/Membership';
import './App.css'; // CSS 파일이 필요하다면

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/notices" element={<Notices />} />
        <Route path="/location" element={<Location />} />
        <Route path="/academy" element={<Academy />} />
        <Route path="/membership" element={<Membership />} />
      </Routes>
    </Router>
  );
}

export default App;
