import React from 'react';
import locationMap from '../photo/location_img.png'; // The map image
import arrowIcon from '../photo/arrow.png'; // The arrow image
import NavigationBar from './NavigationBar';
import '../css/location.css'; // Import the CSS file

function Location() {
  return (
    <div className="location-container">      
      <NavigationBar />
      
      {/* Background design */}
      <div className="location-background">
        
        {/* Container for the map and text */}
        <div className="location-content">
          <div className="location-box">
            {/* Map image */}
            <img src={locationMap} alt="Location Map" className="location-map" />

            {/* Address and information below the map */}
            <div className="location-info">
              <p className="location-address">충북 청주시 흥덕구 직지대로 757번길 7, 1층 피피밀</p>
              <p className="location-parking">
                <img src={arrowIcon} alt="Arrow Icon" className="arrow-icon" />
                주차 안내: 별도의 주차장 마련되어 있지 않습니다.<br />
                공영주차장을 이용하셔야 합니다. 근처 유료주차장으로는<br />
                '고인쇄 박물관' 주차장을 추천합니다. (도보 5분)
              </p>
              <p className="location-contact">
                <span className="location-inquiry">문의</span> 전화 070-7773-2299 이메일 intersect.xo@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Location;
