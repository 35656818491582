import React from 'react';
import NavigationBar from './NavigationBar';
import '../css/membership.css';
import '../css/home.css';


function Membership() {
  return (
    <div className="header">
    <NavigationBar />
    <div className="membership_background"></div>
  </div>
);}

export default Membership;